import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import createHistory from 'history/createBrowserHistory'

import ReactGA from 'react-ga'

import Home from './screens/Home'

import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';


function App() {
  ReactGA.initialize('UA-203011646-3')
  const history = createHistory();
  
  history.listen(location => {
    ReactGA.set({ page: location.pathname })
    ReactGA.pageview(location.pathname)
  })

  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search)
  }, [history])

  return (
    <>
      <Router>
        <Switch>
          <Route exact path="/" component={Home} />
        </Switch>
      </Router>
    </>
  );
}

export default App;
