import React, { useEffect, useState } from 'react'
import axios from 'axios'
import { Container, OverlayTrigger, Popover } from 'react-bootstrap'
import { Discord, Telegram , XCircleFill, InfoCircleFill } from 'react-bootstrap-icons'
import ReactCountryFlag from 'react-country-flag'
import _ from 'lodash'
import ReactGA from 'react-ga'
import Toggle from 'react-toggle'
import 'react-toggle/style.css'

const API_BASE_URL = "https://api.spacepools.net"


export default function Home() {
    const [pools, setPools] = useState([])
    const [filterCryptoFriendlyCountry, setFilterCryptoFriendlyCountry] = useState(false)
    const [filterNoUsaRelations, setFilterNoUsaRelations] = useState(false)
    const [filterRegisteredCompany, setFilterRegisteredCompany] = useState(false)
    const [filterSupportsDecentralization, setFilterSupportsDecentralization] = useState(false)
    const [filterZeroLifetimeFeesForEarlyBird, setfilterZeroLifetimeFeesForEarlyBird] = useState(false)
    
    useEffect(() => {
        axios.get(`${API_BASE_URL}/pools`)
            .then(res => {
                setPools(res.data.pools)
            })
            .catch(err => {
                console.error(`Error getting pools: ${err}`)
            })
    }, [])

    const renderCommunityIcon = (platform, url) => {
        switch (platform) {
            case 'Discord':
                return (
                    <a href={url}>
                        <Discord size={25} color="#7289da" />
                    </a>
                )
            case 'Telegram':
                return (
                    <a href={url}>
                        <Telegram size={25} color="#0088cc" />
                    </a>
                )
            default:
                return 
        }
    }

    const renderCountryFlag = (countryCode) => {
        if (countryCode) {
            return (
                <ReactCountryFlag 
                    countryCode={countryCode} 
                    svg 
                    title={countryCode} 
                    style={{
                        width: '1.8em',
                        height: '1.8em'
                    }}
                />
            )
        } else {
            return (
                <XCircleFill color="#DDD" />
            )
        }
    }

    const formatCapacity = (capacityTib) => {
        if (!capacityTib) {
            return '-'
        }

        let units = 'TiB'
        let capacity = capacityTib

        if (capacityTib > 1024 ** 2) {
            capacity = capacityTib / 1000 ** 2
            units = 'EiB'
        } 
        else if (capacityTib > 1000 ** 1) {
            capacity = capacityTib / 1024 ** 1
            units = 'PiB'
        }

        return `${capacity.toFixed(1)} ${units}`
    }

    const getFeatureClasses = (feature) => {
        let classNames = 'bg-secondary'
        // switch (feature.id) {
        //     case '0-lifetime-fees-for-early-bird':
        //         classNames = 'bg-success'
        //         break
        //     case 'crypto-friendly-country':
        //         classNames = 'bg-primary'
        //         break
        //     case 'registered-company':
        //         classNames = 'bg-info'
        //         break
        //     case 'no-usa-relations':
        //         classNames = 'bg-warning'
        //         break
        //     case 'supports-decentralization':
        //         classNames = 'bg-danger'
        //         break
        //     default:
        //         break;
        // }
        return classNames
    }

    const handleToggleCryptoFriendlyCountry = (e) => {
        setFilterCryptoFriendlyCountry(e.target.checked)
        ReactGA.event({
            category: 'User',
            action: 'Toggled filter',
            label: 'crypto-friendly-country',
        })
    }

    const handleToggleNoUsaRelations = (e) => {
        setFilterNoUsaRelations(e.target.checked)
        ReactGA.event({
            category: 'User',
            action: 'Toggled filter',
            label: 'no-usa-relations',
        })
    }

    const handleToggleRegisteredCompany = (e) => {
        setFilterRegisteredCompany(e.target.checked)
        ReactGA.event({
            category: 'User',
            action: 'Toggled filter',
            label: 'registered-company',
        })
    }


    const handleToggleSupportsDecentralization = (e) => {
        setFilterSupportsDecentralization(e.target.checked)
        ReactGA.event({
            category: 'User',
            action: 'Toggled filter',
            label: 'supports-decentralization',
        })
    }


    const handleToggleZeroLifetimePoolFeesForEarlyBird = (e) => {
        setfilterZeroLifetimeFeesForEarlyBird(e.target.checked)
        ReactGA.event({
            category: 'User',
            action: 'Toggled filter',
            label: 'zero-lifetime-fees-for-early-bird',
        })
    }

    const handleOutboundLinkClick = (url) => {
        ReactGA.event({
            category: 'User',
            action: 'Clicked outbound link',
            label: url,
        })
    }

    const popoverCryptoFriendlyCountry = (
        <Popover style={{marginLeft: 10}}>
            <Popover.Content>
                Countries that are the most friendly towards cryptocurrencies include Malta, Singapore, Switzerland, Germany, Cyprus, Bermuda. 
            </Popover.Content>
        </Popover>
    );

    const popoverNoUsaRelations = (
        <Popover style={{marginLeft: 10}}>
            <Popover.Content>
                USA-domiciled pools are non-ideal due to their heavily regulated environment with legal and tax ramifications.
            </Popover.Content>
        </Popover>
    );

    const popoverRegisteredCompany = (
        <Popover style={{marginLeft: 10}}>
            <Popover.Content>
                Indicates that the pool is legitimate and professional.
            </Popover.Content>
        </Popover>
    );

    const popoverSupportsDecentralization = (
        <Popover style={{marginLeft: 10}}>
            <Popover.Content>
                Farm with the smaller pools to help decentralize the network. You will not earn more with a larger pool.
            </Popover.Content>
        </Popover>
    );

    const popoverZeroLifetimeFeeForEarlyBird = (
        <Popover style={{marginLeft: 10}}>
            <Popover.Content>
                Special perk that rewards early supporters of a pool.
            </Popover.Content>
        </Popover>
    );

    const popoverIncorporated = (
        <Popover style={{marginLeft: 10}}>
            <Popover.Content className="center-text">
                Verified by SpacePools.net
            </Popover.Content>
        </Popover>
    );

    const popoverCapacity = (
        <Popover style={{marginLeft: 10}}>
            <Popover.Content className="center-text">
                Self-reported by pool. May not be accurate.
            </Popover.Content>
        </Popover>
    );

    const popoverFarmers = (
        <Popover style={{marginLeft: 10}}>
            <Popover.Content className="center-text">
                Self-reported by pool. May not be accurate.
            </Popover.Content>
        </Popover>
    );


    return (
        <Container fluid>
            <div className="header-title-container center-text">
                <h1 className="header-title">Chia</h1>
                <h1 className="header-title header-blue">Space Pools</h1>
                <img src="assets/img/spacepools_avatar.png" width="400" />
            </div>

            <div className="filters-container">
                <div className="filter-toggle">
                    <Toggle
                        aria-label="Crypto-friendly country"
                        onChange={handleToggleCryptoFriendlyCountry}
                    />
                    <OverlayTrigger trigger="hover" placement="right" overlay={popoverCryptoFriendlyCountry}>
                        <span className={filterCryptoFriendlyCountry ? "name marker-highlight" : "name"}>
                            Crypto-friendly country
                        </span>
                    </OverlayTrigger>
                </div>

                <div className="filter-toggle">
                    <Toggle
                        aria-label="No USA relations"
                        onChange={handleToggleNoUsaRelations}
                    />
                    <OverlayTrigger trigger="hover" placement="right" overlay={popoverNoUsaRelations}>
                        <span className={filterNoUsaRelations ? "name marker-highlight" : "name"}>No USA relations</span>
                    </OverlayTrigger>
                </div>

                <div className="filter-toggle">
                    <Toggle
                        aria-label="Registered company"
                        onChange={handleToggleRegisteredCompany}
                    />
                    <OverlayTrigger trigger="hover" placement="right" overlay={popoverRegisteredCompany}>
                        <span className={filterRegisteredCompany ? "name marker-highlight" : "name"}>Registered company</span>
                    </OverlayTrigger>
                </div>

                <div className="filter-toggle">
                    <Toggle
                        aria-label="Supports decentralization"
                        onChange={handleToggleSupportsDecentralization}
                    />
                    <OverlayTrigger trigger="hover" placement="right" overlay={popoverSupportsDecentralization}>
                        <span>
                        <span className={filterSupportsDecentralization ? "name marker-highlight" : "name"}>Supports decentralization</span> 
                        <span className="badge bg-success" style={{marginLeft: 10}}>Recommended</span>
                        </span>
                    </OverlayTrigger>
                </div>
{/* 
                <div className="filter-toggle">
                    <Toggle
                        aria-label="Zero lifetime pool fees for early farmers"
                        onChange={handleToggleZeroLifetimePoolFeesForEarlyBird}
                    />
                    <OverlayTrigger trigger="hover" placement="right" overlay={popoverZeroLifetimeFeeForEarlyBird}>
                        <span className={filterZeroLifetimeFeesForEarlyBird ? "name marker-highlight" : "name"}>Zero lifetime pool fees for early farmers</span>
                    </OverlayTrigger>
                </div> */}
            </div>

            <table className="pools-table">
                <thead>
                    <tr>
                        <th>Name</th>
                        <th>Base Fee</th>
                        <th>Min. Payout</th>
                        <th>Features</th>
                        <th>Community</th>
                        <th>Incorporated <OverlayTrigger trigger="hover" placement="top" overlay={popoverIncorporated}><InfoCircleFill color="#CCC" style={{marginTop: -5}}/></OverlayTrigger></th>
                        <th>Capacity <OverlayTrigger trigger="hover" placement="top" overlay={popoverCapacity}><InfoCircleFill color="#CCC" style={{marginTop: -5}}/></OverlayTrigger></th>
                        <th>Farmers <OverlayTrigger trigger="hover" placement="top" overlay={popoverFarmers}><InfoCircleFill color="#CCC" style={{marginTop: -5}}/></OverlayTrigger></th>
                    </tr>
                </thead>
                <tbody>
                    {pools
                        .sort((a, b) => (a.capacity_tib < b.capacity_tib) ? 1 : -1)
                        .sort((a, b) => (!a.capacity_tib) ? 1 : -1)
                        .filter(pool => {
                            if (filterCryptoFriendlyCountry) {
                                if (!_.some(pool.features, ['id', "crypto-friendly-country"])) return false
                            } 
                            if (filterNoUsaRelations) {
                                if (!_.some(pool.features, ['id', "no-usa-relations"])) return false
                            } 
                            if (filterRegisteredCompany) {
                                if (!_.some(pool.features, ['id', "registered-company"])) return false
                            } 
                            if (filterSupportsDecentralization) {
                                if (!_.some(pool.features, ['id', "supports-decentralization"])) return false
                            } 
                            if (filterZeroLifetimeFeesForEarlyBird) {
                                if (!_.some(pool.features, ['id', "0-lifetime-fees-for-early-bird"])) return false
                            }                            
                            return true
                        })
                        .map(pool => (
                            <tr key={pool.name}>
                                <td className="name">
                                    <div className="title" style={{whiteSpace: 'nowrap'}}>
                                        <a href={pool.url} target="_blank" onClick={() => handleOutboundLinkClick(pool.url)}>
                                            <img src={`/assets/img/pool/${pool.icon_filename}`} className="icon" alt="" />
                                        </a>
                                        <span className="text">
                                            <a href={pool.url} target="_blank" onClick={() => handleOutboundLinkClick(pool.url)}>{pool.name}</a>
                                        </span>
                                    </div>
                                </td>
                                <td style={{whiteSpace: 'nowrap'}}>{pool.base_fee ? pool.base_fee + ' %' : 'TBD'}</td>
                                <td>{pool.minimum_payout !== null ? pool.minimum_payout : '-'}</td>
                                <td>
                                    {
                                        pool.features.map(feature => (
                                            <span className={"badge rounded-pill " + getFeatureClasses(feature)} key={feature.name}>{feature.name}</span>
                                        ))
                                    }
                                </td>
                                <td className="center-text">{renderCommunityIcon(pool.community_platform, pool.community_url)}</td>
                                <td className="center-text">{renderCountryFlag(pool.incorporated_country)}</td>
                                <td className="right-align-text"style={{whiteSpace: 'nowrap'}}>{formatCapacity(pool.capacity_tib) || '-'}</td>
                                <td className="right-align-text">{pool.farmers ? pool.farmers.toLocaleString() : '-'}</td>
                            </tr>
                        ))
                    }
                </tbody>
            </table>

            <br />

            <div style={{textAlign: 'center'}}>
                <p>For feedback and pool submissions, please contact <a href="mailto:admin@spacepools.net">admin@spacepools.net</a></p>
            </div>
        </Container>
    )
}